import React, { useEffect, useRef } from "react";
import { Box, HStack } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import LightGem from "./LightGem";

const LightThanosBar = () => {
  const history = useHistory();
  const location = useLocation();

  const { isBtn, PeBtn, frBtn, baBtn, smBtn, suBtn, id, pageUrl, categoryId } =
    useSelector((state: any) => ({
      isBtn: state.media.isBtn,
      PeBtn: state.media.PeBtn,
      frBtn: state.media.frBtn,
      baBtn: state.media.baBtn,
      smBtn: state.media.smBtn,
      suBtn: state.media.suBtn,
      id: state.media.id,
      pageUrl: state.media.pageUrl,
      categoryId: state.media.categoryId,
    }));

  useEffect(() => {
    if (smBtn) return;
    if (history.location.pathname === pageUrl) {
      setTimeout(() => {
        const categoryElement = document.getElementById(
          `category-${categoryId}`
        );
        if (categoryElement) {
          if (categoryElement) {
            categoryElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            categoryElement.focus();
            const accordionButton = categoryElement.querySelector(
              ".chakra-accordion__button"
            ) as HTMLButtonElement;
            if (
              accordionButton &&
              accordionButton.getAttribute("aria-expanded") === "false"
            ) {
              accordionButton.click();
            }
          }
        } else {
          setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center" });
              element.focus();
            }
          }, 300);
        }
      }, 500);
    }
  }, [history.location.pathname, pageUrl, categoryId, id, smBtn]);

  const handleNavigation = (btnName: string) => {
    if (btnName === "isBtn") {
      history.push("/content/affirmation/sendingintention/Self-Esteem");
      return;
    }
    if (btnName === "PeBtn") {
      history.push("/content/affirmation/clearandprotect/area");
      return;
    }

    if (btnName === "frBtn" && pageUrl === "/content/frequency/solfeggio") {
      history.push("/content/frequency/solfeggio");
      return;
    }

    let elementId = id;

    if (elementId) {
      if (location.pathname !== pageUrl) {
        history.push(pageUrl);
      } else {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
          element.focus();
        }
      }
    }
  };

  return (
    <Box display="inline-block">
      <HStack gridGap="2">
        <LightGem
          color={baBtn ? "purple" : "gray.100"}
          textColor={baBtn ? "white" : "gray.900"}
          text="BA"
          onClick={baBtn ? () => handleNavigation("baBtn") : () => {}}
          isPointer={baBtn ? true : false}
        />
        <LightGem
          color={frBtn ? "yellow" : "gray.100"}
          textColor={frBtn ? "black" : "gray.900"}
          text="Fr"
          onClick={frBtn ? () => handleNavigation("frBtn") : () => {}}
          isPointer={frBtn ? true : false}
        />
        <LightGem
          color={suBtn ? "indigo" : "gray.100"}
          textColor={suBtn ? "white" : "gray.900"}
          text="Su"
          onClick={suBtn ? () => handleNavigation("suBtn") : () => {}}
          isPointer={suBtn ? true : false}
        />
        <LightGem
          color={isBtn ? "red" : "gray.100"}
          textColor={isBtn ? "white" : "gray.900"}
          text="is"
          onClick={isBtn ? () => handleNavigation("isBtn") : () => {}}
          isPointer={isBtn ? true : false}
        />
        <LightGem
          color={smBtn ? "yellow" : "gray.100"}
          textColor={smBtn ? "black" : "gray.900"}
          text="SM"
          onClick={smBtn ? () => handleNavigation("smBtn") : () => {}}
          isPointer={smBtn ? true : false}
        />
        <LightGem
          color={PeBtn ? "green" : "gray.100"}
          textColor={PeBtn ? "white" : "gray.900"}
          text="PE"
          onClick={PeBtn ? () => handleNavigation("PeBtn") : () => {}}
          isPointer={PeBtn ? true : false}
        />
      </HStack>
    </Box>
  );
};

export default LightThanosBar;
