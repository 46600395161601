import React, { useState, useRef, ChangeEvent } from "react";
import {
  Box,
  Flex,
  IconButton,
  Text,
  Center,
  Avatar,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { Camera } from "lucide-react";
import { useIntl } from "react-intl";
import { UserType } from "src/types";
import { put, uploadFile } from "src/api";
import { useDispatch, useSelector } from "src/store";
import { update } from "src/store/auth";
import { BASE_MEDIA } from "src/configs/config";

const ProfileImage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { user: data } = useSelector((state) => state.auth);

  //uploading value for profile picture
  const [uploading, setUploading] = useState<number>(0);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [currentPicture, setCurrentPicture] = useState(data?.picture);

  const dispatch = useDispatch();
  const toast = useToast();

  //function to handle input change
  const handleFileChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    if (target.files) {
      const file = target.files[0];
      if (!file) return;
      const successCallback = async (url: string) => {
        const response: UserType = await put(
          "/user/me/picture",
          { picture: url },
          undefined,
          data?.accessToken
        );
        if (response?.id) {
          setCurrentPicture(url);
          dispatch(update({ user: { ...data, picture: url } }));
          toast({
            status: "success",
            title: formatMessage({ id: "saved" }),
            duration: 4000,
          });
        } else
          toast({
            status: "error",
            title: formatMessage({ id: "wentWrong" }),
            duration: 4000,
          });
      };
      const failureCallback = () => {
        toast({
          status: "error",
          title: formatMessage({ id: "wentWrong" }),
          duration: 4000,
        });
      };
      uploadFile(
        file,
        setUploading,
        successCallback,
        failureCallback,
        data?.accessToken
      );
    }
  };

  return (
    <Box margin="5px auto">
      <Center d="flex" flexDir="column" position="relative">
        <Box position="relative">
          <Avatar
            outline="3px solid #162d6a80"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
            size="2xl"
            src={`${BASE_MEDIA}/${currentPicture}` || ""}
            cursor="pointer"
            onClick={() => !uploading && fileInputRef.current?.click()}
            opacity={uploading ? 0.5 : 1}
          />
          <IconButton
            aria-label={formatMessage({ id: "uploadPhoto" })}
            icon={uploading > 0 ? <Spinner size="xs" /> : <Camera size={16} />}
            size="sm"
            color="white"
            bg="#162d6a"
            rounded="full"
            position="absolute"
            bottom="0"
            right="0"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
            onClick={() => fileInputRef.current?.click()}
            disabled={uploading > 0}
          />
        </Box>

        <Flex flexDir="column" align="center" mt={2}>
          <Text fontSize="xl" fontWeight="semibold">
            {data?.name}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {data?.email}
          </Text>
        </Flex>

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          style={{ display: "none" }}
          disabled={uploading > 0}
        />
      </Center>
    </Box>
  );
};

export default ProfileImage;
