import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Box,
  Stack,
  FormLabel,
  Input,
  Select,
  Button,
  Text,
  InputGroup,
  InputLeftElement,
  IconButton,
  InputRightElement,
  VStack,
  Flex,
  useToast,
} from "@chakra-ui/react";
import {
  User,
  Mail,
  Phone,
  Globe,
  Lock,
  Eye,
  EyeOff,
  Edit2,
} from "lucide-react";
import { get, put } from "src/api/index";
import useLocale from "src/providers/useLocale";
import { useIntl } from "react-intl";
import Flag from "react-flagpack";
import { getCountryCode } from "src/views/Register/countries";
import { useDispatch, useSelector } from "src/store";
import { update } from "src/store/auth";

type FormData = {
  fullName: string;
  email: string;
  phone: string;
  country: Country;
  verificationPassword?: string;
};

type PasswordData = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

type Country = {
  id: number;
  name: string;
  region: string;
};

type ApiError = {
  message: string | string[];
  statusCode?: number;
};

const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const PersonalInfo: React.FC = () => {
  const { formatMessage } = useIntl();
  const { user: data } = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [countries, setCountries] = useState<Array<Country>>([]);
  const [locale] = useLocale();
  const [showVerificationPassword, setShowVerificationPassword] =
    useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const [errors, setErrors] = useState<string[]>([]);
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [fullNameError, setFullNameError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const [countryError, setCountryError] = useState<string>("");

  const initialData: FormData = {
    fullName: data?.name || "",
    email: data?.email || "",
    phone: data?.phone || "",
    country: {
      id: data?.country?.id || 0,
      name: data?.country?.name || "",
      region: data?.country?.region || "",
    },
  };

  const initialPasswordData: PasswordData = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [formData, setFormData] = useState<FormData>(initialData);
  const [passwordData, setPasswordData] =
    useState<PasswordData>(initialPasswordData);

  const hasChanges =
    JSON.stringify(formData) !== JSON.stringify(initialData) ||
    (isUpdatingPassword && Object.values(passwordData).some((value) => value));

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case "fullName":
        setFullNameError(
          !value ? formatMessage({ id: "fullNameRequired" }) : ""
        );
        break;
      case "email":
        setEmailError(
          !value
            ? formatMessage({ id: "emailRequired" })
            : !isValidEmail(value)
            ? formatMessage({ id: "invalidEmail" })
            : ""
        );
        break;
      case "phone":
        setPhoneError(!value ? formatMessage({ id: "phoneRequired" }) : "");
        break;
      case "country":
        setCountryError(!value ? formatMessage({ id: "countryRequired" }) : "");
        const selectedCountry = countries.find((c) => c.id === Number(value));
        if (selectedCountry) {
          setFormData((prev) => ({
            ...prev,
            country: {
              id: selectedCountry.id,
              name: selectedCountry.name,
              region: selectedCountry.region,
            },
          }));
        }
        break;
    }

    if (
      name === "oldPassword" ||
      name === "newPassword" ||
      name === "confirmPassword"
    ) {
      setPasswordData((prev) => ({ ...prev, [name]: value }));
    } else if (name !== "country") {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleCancel = () => {
    setFormData({ ...initialData, verificationPassword: "" });
    setPasswordData(initialPasswordData);
    setIsUpdatingPassword(false);
    setIsEditing(false);
    // Clear all error messages
    setErrors([]);
    setEmailError("");
    setPasswordError("");
  };

  const handleSave = async () => {
    try {
      setErrors([]);
      let response;

      if (isUpdatingPassword) {
        response = await put(
          "/user/me/password",
          {
            oldPassword: passwordData.oldPassword,
            newPassword: passwordData.newPassword,
          },
          locale,
          data?.accessToken
        );
      } else {
        response = await put(
          "/user/me",
          {
            name: formData.fullName,
            email: formData.email,
            phone: formData.phone,
            countryId: formData.country.id,
            password: formData.verificationPassword,
          },
          locale,
          data?.accessToken
        );
        window.localStorage.setItem("__sal_auth", response.token);
      }

      if (response?.user?.id || response?.id) {
        dispatch(update(response));
        toast({
          title: formatMessage({ id: "saved" }),
          status: "success",
          duration: 3000,
        });
        setIsEditing(false);
        setIsUpdatingPassword(false);
        setPasswordData(initialPasswordData);
        setFormData((prev) => ({ ...prev, verificationPassword: "" }));
      } else {
        const error = response as ApiError;
        if (error.message) {
          const messages = Array.isArray(error.message)
            ? error.message
            : [error.message];
          setErrors(messages);
        } else {
          setErrors([formatMessage({ id: "wentWrong" })]);
        }
      }
    } catch (error: any) {
      const messages = error?.response?.data?.message;
      if (messages) {
        setErrors(Array.isArray(messages) ? messages : [messages]);
      } else {
        setErrors([formatMessage({ id: "wentWrong" })]);
      }
      console.error("Error updating profile:", error);
    }
  };

  const fetchCountries = async () => {
    const response = await get<Array<Country>>("/country", locale);
    if (Array.isArray(response)) setCountries(response);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      sx={{ fontFamily: "Calibri, sans-serif" }}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
    >
      <VStack spacing={6}>
        <Box w="full">
          <Flex justify="space-between" align="center">
            <Text fontSize="2xl" fontWeight="semibold" color="#162d6a">
              {formatMessage({ id: "personalInfo" })}
            </Text>
            {!isEditing && (
              <Button
                aria-label={formatMessage({ id: "editProfile" })}
                leftIcon={<Edit2 size={16} />}
                size="sm"
                variant="outline"
                borderColor="#1f3f93"
                color="#1f3f93"
                bg="white"
                boxShadow="sm"
                _hover={{
                  bg: "#1f3f93",
                  color: "white",
                  borderColor: "#1f3f93",
                }}
                _active={{
                  bg: "#162d6a",
                  borderColor: "#162d6a",
                  color: "white",
                }}
                onClick={() => setIsEditing(true)}
              >
                {formatMessage({ id: "editProfile" })}
              </Button>
            )}
          </Flex>
          <Text w="full" color="gray.500" fontSize="md" mb={0}>
            {formatMessage({ id: "personalInfo.description" })}
          </Text>
        </Box>

        <Stack spacing={6} w="full">
          <Box>
            <FormLabel>{formatMessage({ id: "fullName" })}</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <User size={16} color={!isEditing ? "gray" : "black"} />
                }
              />
              <Input
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                placeholder={formatMessage({ id: "enterFullName" })}
                isReadOnly={!isEditing}
                isInvalid={!!fullNameError}
                border={!isEditing ? "none" : undefined}
                color={!isEditing ? "gray" : undefined}
              />
            </InputGroup>
            {fullNameError && (
              <Text color="red.500" fontSize="sm" mt={1}>
                {fullNameError}
              </Text>
            )}
          </Box>

          <Box>
            <FormLabel>{formatMessage({ id: "email" })}</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Mail size={16} color={!isEditing ? "gray" : "black"} />
                }
              />
              <Input
                name="email"
                value={formData.email}
                onChange={handleChange}
                type="email"
                placeholder={formatMessage({ id: "enterEmail" })}
                isReadOnly={!isEditing}
                isInvalid={!!emailError}
                border={!isEditing ? "none" : undefined}
                color={!isEditing ? "gray" : undefined}
              />
            </InputGroup>
            {emailError && (
              <Text color="red.500" fontSize="sm" mt={1}>
                {emailError}
              </Text>
            )}
          </Box>

          <Box>
            <FormLabel>{formatMessage({ id: "phone" })}</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={
                  <Phone size={16} color={!isEditing ? "gray" : "black"} />
                }
              />
              <Input
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                type="tel"
                placeholder={formatMessage({ id: "enterPhone" })}
                isReadOnly={!isEditing}
                isInvalid={!!phoneError}
                border={!isEditing ? "none" : undefined}
                color={!isEditing ? "gray" : undefined}
                dir={locale === "ar" ? "ltr" : undefined}
                textAlign={locale === "ar" ? "end" : "start"}
                ml={locale === "ar" ? 6 : 0}
              />
            </InputGroup>
            {phoneError && (
              <Text color="red.500" fontSize="sm" mt={1}>
                {phoneError}
              </Text>
            )}
          </Box>

          <Box>
            <FormLabel>{formatMessage({ id: "country" })}</FormLabel>
            <InputGroup>
              <InputLeftElement>
                {formData.country ? (
                  <Flag
                    code={getCountryCode(formData.country.name)}
                    size="s"
                    gradient="real-linear"
                  />
                ) : (
                  <Globe size={16} />
                )}
              </InputLeftElement>
              <Select
                name="country"
                value={formData.country.id}
                onChange={handleChange}
                pl={10}
                isDisabled={!isEditing}
                isInvalid={!!countryError}
                border={!isEditing ? "none" : undefined}
                color={!isEditing ? "gray" : undefined}
              >
                <option value="">
                  {formatMessage({ id: "selectCountry" })}
                </option>
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Select>
            </InputGroup>
            {countryError && (
              <Text color="red.500" fontSize="sm" mt={1}>
                {countryError}
              </Text>
            )}
          </Box>

          {isEditing && !isUpdatingPassword && (
            <Box mt={4}>
              <FormLabel>{formatMessage({ id: "verifyPassword" })}</FormLabel>
              <InputGroup>
                <InputLeftElement children={<Lock size={16} />} />
                <Input
                  name="verificationPassword"
                  value={formData.verificationPassword || ""}
                  onChange={handleChange}
                  type={showVerificationPassword ? "text" : "password"}
                  placeholder={formatMessage({ id: "password" })}
                  isRequired
                />
                <InputRightElement>
                  <IconButton
                    size="sm"
                    variant="ghost"
                    onClick={() =>
                      setShowVerificationPassword(!showVerificationPassword)
                    }
                    icon={
                      showVerificationPassword ? (
                        <EyeOff size={16} />
                      ) : (
                        <Eye size={16} />
                      )
                    }
                    aria-label={
                      showVerificationPassword
                        ? "Hide password"
                        : "Show password"
                    }
                  />
                </InputRightElement>
              </InputGroup>
            </Box>
          )}

          {errors.length > 0 && (
            <Box
              w="full"
              p={4}
              bg="red.50"
              borderRadius="md"
              borderLeft="4px"
              borderColor="red.500"
            >
              <VStack align="stretch" spacing={1}>
                {errors.map((error, index) => (
                  <Text
                    key={index}
                    color="red.600"
                    fontSize="sm"
                    display="flex"
                    alignItems="center"
                  >
                    {error}
                  </Text>
                ))}
              </VStack>
            </Box>
          )}

          {isEditing && (
            <Stack direction="row" spacing={4} justify="flex-end">
              <Button variant="ghost" onClick={handleCancel}>
                {formatMessage({ id: "cancelChanges" })}
              </Button>
              <Button
                color="white"
                bg="#1f3f93"
                _hover={{ bg: "#aaaabc" }}
                isDisabled={
                  !hasChanges ||
                  !!emailError ||
                  (isUpdatingPassword &&
                    (passwordData.newPassword !==
                      passwordData.confirmPassword ||
                      !passwordData.newPassword ||
                      !!passwordError)) ||
                  (!isUpdatingPassword && !formData.verificationPassword)
                }
                onClick={handleSave}
              >
                {formatMessage({ id: "saveChanges" })}
              </Button>
            </Stack>
          )}
        </Stack>
      </VStack>
    </Box>
  );
};

export default PersonalInfo;
