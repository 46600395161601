import {
  Box,
  HStack,
  IconButton,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
  useDisclosure,
  VStack,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { FaStop, FaPause } from "react-icons/fa";

import { MdPlaylistAdd } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { get } from "src/api/index";
import AddToPlaylistModal from "src/components/AddToPlaylistModal";
import useLocale from "src/providers/useLocale";
import { useDispatch, useSelector } from "src/store";
import {
  playMedia,
  stopMedia,
  pauseMedia,
  toggleAudioLoop,
} from "src/store/media";
import { Wave } from "src/types";
import Rating from "../../../components/Rating";
import BaseView from "../BaseView";
import { WAVE_DETAIL_ROUTE } from "src/routes";
import { FaRepeat, FaPlay } from "react-icons/fa6";
const ENTITY_NAME = "waves";

const WaveDetail = () => {
  //location to check the url params
  const { id: waveId } = useParams<any>();

  const history = useHistory();

  //current user
  const user = useSelector((state) => state.auth.user);

  //locale
  const [locale] = useLocale();
  const dispatch = useDispatch();
  const { id, isPlaying, isPaused, loop } = useSelector((state) => ({
    id: state.media.id,
    isPlaying: state.media.playing,
    isPaused: state.media.paused,
    loop: state.media.loop,
  }));

  const closeMedia = () => dispatch(stopMedia());

  const toggleLooping = () => dispatch(toggleAudioLoop());

  //The wave
  const [wave, setWave] = useState<Wave>();
  const [playingWaveType, setPlayingWaveType] = useState<
    "with_music" | "pure_tone"
  >("with_music");

  //handling loading and errors on loading
  const [loading, setLoading] = useState(true);

  const [currentTrack, setCurrentTrack] = useState<any>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  //function to play media
  const play = (source: string, id: number) => {
    dispatch(
      playMedia({
        frBtn: true,
        isStatic: false,
        source: source,
        type: "audio",
        id: `wave-${id}`,
        pageUrl: history.location.pathname,
        loop: loop,
        name: "wave",
      })
    );
  };

  //function to pause media
  const pause = (source: string, id: number) => {
    dispatch(
      pauseMedia({
        frBtn: false,
        source: source,
        type: "audio",
        id: `wave-${id}`,
        name: "wave",
      })
    );
  };

  //function to close media
  const close = () => {
    closeMedia!();
  };

  useEffect(() => {
    //method for fetching wave
    const fetchWave = async () => {
      // const id = location.pathname.split('/')[4];
      const response: Wave = await get(
        `/wave/${waveId}`,
        locale === "en" || locale === "ar" ? locale : undefined,
        user?.accessToken
      );
      if (response.mediaFiles?.length === 0) history.goBack();
      if (response?.id) {
        // @ts-ignore
        setWave(response);
      }
      setLoading(false);
    };
    fetchWave();
  }, [history, locale, user?.accessToken, waveId]);

  function createMarkup(wave) {
    // Since DOMPurify removes all target="_blank" from the markup we put it back
    // to allow user go to an external link in another tap
    return {
      __html:
        DOMPurify.sanitize(wave.html).replace(
          /href/g,
          `target="_blank" href`
        ) || " ",
    };
  }

  return (
    <BaseView breadTitle="" route={WAVE_DETAIL_ROUTE}>
      {loading || !wave ? (
        <Box padding={"20px"}>{loading && "Loading..."}</Box>
      ) : (
        <>
          <VStack
            dir="ltr"
            display={"flex"}
            flexDir={["column", "column", "column", "row"]}
            alignItems={["center", "center", "center", "flex-start"]}
            marginTop={"50px"}
            justifyContent={{ lg: "space-between" }}
            width={{ lg: "750px", xl: "1000px" }}
          >
            <Box marginTop={{ lg: "20px" }}>
              <img
                alt={"wave"}
                src={wave.imageUrl}
                style={{
                  minWidth: "250px",
                  maxWidth: "250px",
                  height: "250px",
                  borderRadius: "10px",
                  marginBottom: "20px",
                }}
              />
            </Box>
            <VStack
              paddingTop={["30px", "30px", "30px", "0"]}
              paddingInline="10px"
              display="flex"
              flexDir="column"
              alignItems={{ base: "center", lg: "flex-start" }}
              dir={locale === "ar" ? "rtl" : "ltr"}
            >
              <SimpleGrid columns={[1, null, 2]} spacing="10px">
                {wave.mediaFiles
                  ?.sort((a: any, b: any) => {
                    return a.order - b.order;
                  })
                  .map((file) => {
                    return (
                      <Tooltip
                        key={file.id}
                        label={file.name}
                        color="white"
                        bg="brand.900"
                        placement={locale === "ar" ? "top-end" : "top-start"}
                      >
                        <Box
                          id={`wave-${file.id}`}
                          cursor="pointer"
                          width={["300px", "300px", "300px", "250px", "300px"]}
                          borderRadius={"10px"}
                          background={"#fcfafa"}
                          padding={"10px"}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          boxShadow={"-2px 3px 5px silver"}
                          transition="250ms ease-out"
                          border="2px solid transparent"
                          _hover={{ borderColor: "#2895ff" }}
                          dir="auto"
                        >
                          <h6
                            style={{
                              maxWidth: "280px",
                              wordBreak: "break-word",
                              width: "fit-content",
                            }}
                          >
                            {file.name}
                          </h6>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {id === `wave-${file.id}` &&
                            (isPlaying || isPaused) ? (
                              <>
                                {isPaused && (
                                  <FaPlay
                                    color={"#2895ff"}
                                    onClick={() => {
                                      playingWaveType === "with_music"
                                        ? play(file.withMusicUrl, file.id)
                                        : play(file.musicFreeUrl, file.id);
                                    }}
                                    style={{ margin: "0 5px" }}
                                    cursor="pointer"
                                  />
                                )}
                                {isPlaying && (
                                  <FaPause
                                    color={"#2895ff"}
                                    onClick={() => {
                                      const source =
                                        playingWaveType === "with_music"
                                          ? file.withMusicUrl
                                          : file.musicFreeUrl;
                                      pause(source, file.id);
                                    }}
                                    style={{ margin: "0 5px" }}
                                    cursor="pointer"
                                  />
                                )}
                                <FaStop
                                  color={"#2895ff"}
                                  onClick={close}
                                  style={{ margin: "0 5px" }}
                                  cursor="pointer"
                                />
                                <FaRepeat
                                  color={loop ? "#2895ff" : "#808080"}
                                  onClick={toggleLooping}
                                  style={{ margin: "0 5px" }}
                                  cursor="pointer"
                                />
                              </>
                            ) : (
                              <span
                                style={
                                  locale === "ar"
                                    ? {
                                        transform: "rotateY(180deg)",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }
                                    : {
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }
                                }
                              >
                                {file.withMusicUrl && (
                                  <Tooltip
                                    label={"With Music"}
                                    key={file.id}
                                    color="white"
                                    bg="brand.900"
                                  >
                                    <button style={{ minWidth: "20px" }}>
                                      <Image
                                        loading="lazy"
                                        alt="SAL Logo"
                                        src="/wave.png"
                                        me="auto"
                                        height="15px"
                                        marginRight={1}
                                        color={"#2895ff"}
                                        style={{
                                          cursor: "pointer",
                                          opacity: 0.6,
                                        }}
                                        onClick={() => {
                                          setPlayingWaveType("with_music");
                                          play(file.withMusicUrl, file.id);
                                        }}
                                      />
                                      {/* <MdMusicNote
                                        size={24}
                                        color={"#2895ff"}
                                        
                                        style={{ margin: "0 5px" }}
                                        cursor="pointer"
                                      /> */}
                                    </button>
                                  </Tooltip>
                                )}

                                {file.musicFreeUrl && (
                                  <Tooltip
                                    label={"Pure Tones"}
                                    key={file.id}
                                    color="white"
                                    bg="brand.900"
                                  >
                                    <button style={{ minWidth: "20px" }}>
                                      <Image
                                        loading="lazy"
                                        alt="SAL Logo"
                                        src="/pure-tone.png"
                                        me="auto"
                                        height="15px"
                                        marginRight={1}
                                        color={"#2895ff"}
                                        style={{
                                          cursor: "pointer",
                                          opacity: 0.6,
                                          transform:
                                            locale === "ar" ? "scaleX(-1)" : "",
                                        }}
                                        onClick={() => {
                                          setPlayingWaveType("pure_tone");
                                          play(file.musicFreeUrl, file.id);
                                        }}
                                      />
                                    </button>
                                  </Tooltip>
                                )}
                              </span>
                            )}

                            <IconButton
                              // color="#e97575"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setCurrentTrack(file);
                                onOpen();
                              }}
                              variant="link"
                              aria-label="delete"
                              fontSize="23px"
                              icon={<MdPlaylistAdd />}
                              h="5"
                            />
                          </div>
                        </Box>
                      </Tooltip>
                    );
                  })}
              </SimpleGrid>
              <HStack
                display={"flex"}
                flexDir={"column"}
                width={"100%"}
                textAlign={["center", "center", "center", "start"]}
                dir="auto"
              >
                <Box paddingTop={"10px"} width={"100%"} maxW="400px">
                  {!wave.html &&
                    !wave.text &&
                    wave.audioFilesText?.split("-").map(
                      (txt, idx) =>
                        txt && (
                          <Box
                            key={idx}
                            margin={{ base: "10px auto", lg: "10px 0" }}
                            wordBreak="break-word"
                          >
                            {txt}
                          </Box>
                        )
                    )}
                </Box>
              </HStack>
            </VStack>
          </VStack>
          {!wave.html && !wave.text ? (
            <HStack
              display={"flex"}
              flexDir={{ base: "column", lg: "row" }}
              justifyContent="space-between"
              textAlign={["center", "center", "center", "start"]}
              dir="auto"
              alignItems={{ base: "center", lg: "flex-start" }}
              width={{ lg: "750px", xl: "1000px" }}
            >
              <Box flexGrow={1} padding="10px" maxWidth={{ lg: "50%" }}>
                <Text
                  color="#b3b1ad"
                  fontWeight="bold"
                  fontSize={"20px"}
                  width={"100%"}
                >
                  <FormattedMessage id="benefits" />
                </Text>
                <UnorderedList paddingTop="10px" width={"fit-content"}>
                  {wave.benefits?.split("-").map(
                    (txt, idx) =>
                      txt && (
                        <ListItem key={idx}>
                          <Text>{txt}</Text>
                        </ListItem>
                      )
                  )}
                </UnorderedList>
              </Box>
              <Box flexGrow={1} padding="10px" maxWidth={{ lg: "50%" }}>
                <Text
                  color="#b3b1ad"
                  fontWeight="bold"
                  fontSize={"20px"}
                  width={"100%"}
                >
                  <FormattedMessage id="guides" />
                </Text>
                <UnorderedList paddingTop="10px" width={"fit-content"}>
                  {wave.guides?.split("-").map(
                    (txt, idx) =>
                      txt && (
                        <ListItem key={idx}>
                          <Text>{txt}</Text>
                        </ListItem>
                      )
                  )}
                </UnorderedList>
              </Box>
            </HStack>
          ) : null}
          <Box
            dangerouslySetInnerHTML={createMarkup(wave)}
            style={{ padding: "20px" }}
            className="description"
          >
            {/* here we inject the html of the wave */}
          </Box>
          <Rating entityId={waveId} entityName={ENTITY_NAME} />
          <AddToPlaylistModal
            isOpen={isOpen}
            onClose={onClose}
            track={makeTrackFromWave(currentTrack)}
            entityName={ENTITY_NAME}
          />
        </>
      )}
    </BaseView>
  );
};

export default WaveDetail;
const makeTrackFromWave = (wave: Wave | undefined) => {
  if (!wave) return;
  return {
    ...wave,
    entityName: ENTITY_NAME,
    entityId: wave.id,
  };
};
